import React from 'react';

import TweenMax from 'gsap';
import { TEXT_TYPE } from '../../types/textTypes';
import withMediaTypes from '../../types/withMediaTypes';

import Text from '../Text/Text';

class AboutUsTestimonials extends React.PureComponent {
  swiperRef = React.createRef();

  part1Ref = React.createRef();

  part2Ref = React.createRef();

  part3Ref = React.createRef();

  
  static propTypes = {
    ...withMediaTypes,
  };

  componentDidMount() {
    this.onMoodSelect('zzzz', 0);
  }

  onMoodSelect = (mood, transitionDuration = 0.6) => {
    // eslint-disable-next-line
    const gradients = {
      chill: 'linear-gradient(to top, #e31c79, #f557a1 39%, #f74397 62%, #e31c79)',
      zzzz: 'linear-gradient(to top, #981d97, #ce1ca9 39%, #c438c3 62%, #981d97)',
      fresh: 'linear-gradient(to top, #f4364c, #ff3f69 39%, #ff3f69 62%, #f4364c)',
      lively: 'linear-gradient(to top, #ff6534, #ff9323 34%, #ff8200 46%, #ff6534)',
      dialed: 'linear-gradient(to top, #ffae00, #ffd83a 34%, #ffcd00 71%, #ffbc00)',
    };
    const solids = {
      chill: '#C81468',
      zzzz: '#7A0F79',
      fresh: '#D7293D',
      lively: '#FF5E00',
      dialed: '#FFB400',
    };
    TweenMax.to(this.part1Ref.current, transitionDuration, {
      backgroundColor: solids[mood],
    });
  }

  render() {
    return (
      <section className="AboutUsTestimonials" ref={this.part1Ref}>
        <Text
          className="AboutUsTestimonials--header fim fim-fade-in"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
          size={TEXT_TYPE.h3}
          tag="p"
          color="white"
          value="But don’t take our word for it."
        />
        <div className="AboutUsTestimonials--companies">
          <div
            className="AboutUsTestimonials--companies--company fim fim-fade-in"
            fim-delay="0.5"
          >
            <div className="AboutUsTestimonials--companies--company--copy">
              <Text
                className="AboutUsTestimonials--companies--company--header"
                size={TEXT_TYPE.body}
                tag="p"
                color="white"
                value="#45 on the 2018 Inc. 5000 List of Fastest-growing U.S. companies."
              />
            </div>
            <img src="/about-us/inc5000.png" alt="Inc 5000" />
          </div>
          <div
            className="AboutUsTestimonials--companies--company fim fim-fade-in"
            fim-delay="0.8"
          >
            <div className="AboutUsTestimonials--companies--company--copy">
              <Text
                className="AboutUsTestimonials--companies--company--header"
                size={TEXT_TYPE.body}
                tag="p"
                color="white"
                value="Ranked as one of the Top<br/>100 Best Companies to Work for in 2018 and 2019."
              />
            </div>
            <img src="/about-us/oregon-business.png" alt="Oregon Business" />
          </div>
          <div
            className="AboutUsTestimonials--companies--company fim fim-fade-in"
            fim-delay="1.1"
          >
            <div className="AboutUsTestimonials--companies--company--copy">
              <Text
                className="AboutUsTestimonials--companies--company--header"
                size={TEXT_TYPE.body}
                tag="p"
                color="white"
                value="Best-selling cannabis brand on the West Coast."
              />
            </div>
            <img src="/about-us/dbs-analytics.png" alt="DBS Analytics" />
          </div>
        </div>
        <img
          className="AboutUsTestimonials--select-better fim fim-fade-in"
          src="/about-us/select-better.svg"
          alt="Select Better"
        />
      </section>
    );
  }
}

export default AboutUsTestimonials;
