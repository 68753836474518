import React from 'react';

import withMediaTypes from '../../types/withMediaTypes';
import withMedia from '../../hoc/withMedia';

import AboutUsHero from './AboutUsHero';

import './AboutUsView.scss';
import AboutUsSlider from './AboutUsSlider';
import AboutUsTestimonials from './AboutUsTestimonials';

class AboutUsView extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
  };

  render() {
    return (
      <article className="AboutUsView">
        <AboutUsHero {...this.props} />
        <AboutUsSlider {...this.props} />
        <AboutUsTestimonials {...this.props} />
      </article>
    );
  }
}

export default withMedia(AboutUsView);
