import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import AboutUsView from '../components/AboutUsView';

import '../styles/global.scss';

class AboutUsPage extends React.PureComponent {
  render() {
    return (
      <Layout view="about-us">
        <Seo title="About Us" />
        <AboutUsView />
      </Layout>
    );
  }
}

export default AboutUsPage;
