import React from 'react';
import { TweenMax, Power2 } from 'gsap/TweenMax';

import { TEXT_TYPE } from '../../types/textTypes';
import withMediaTypes from '../../types/withMediaTypes';

import Text from '../Text';
import LayoutContext from '../Layout/LayoutContext';

class AboutUsHero extends React.PureComponent {
  
  static propTypes = {
    ...withMediaTypes,
  };

  componentDidMount() {
    this.isLoggedIn = this.context.isLoggedIn;
    if (this.isLoggedIn) {
      setTimeout(this.animateIn, 1000);
    }
    setTimeout(
      () => {
        TweenMax.to(['#AboutUsHero--header', '#AboutUsHero--desc'], 0, {
          autoAlpha: 0,
          y: 10,
        });
      },
      this.isLoggedIn ? 100 : 1000,
    );
  }

  componentDidUpdate() {
    if (this.isLoggedIn !== this.context.isLoggedIn) {
      clearTimeout(this.animateTimeout);
      this.animateTimeout = setTimeout(this.animateIn, 1500);
    }
  }

  animateIn = () => {
    TweenMax.to('#AboutUsHero--header', 0.7, {
      autoAlpha: 1,
      y: 0,
      ease: Power2.easeOut,
    });
    TweenMax.to('#AboutUsHero--desc', 0.7, {
      autoAlpha: 1,
      y: 0,
      ease: Power2.easeOut,
      delay: 0.2,
    });
  };

  static contextType = LayoutContext;

  render() {
    return (
      <section className="AboutUsHero">
        <div className="AboutUsHero--text-container">
          <Text
            id="AboutUsHero--header"
            className="AboutUsHero--text-container--header"
            size={TEXT_TYPE.h2}
            tag="div"
            color="white"
            value="It all started with a better idea."
          />
          <Text
            id="AboutUsHero--desc"
            className="AboutUsHero--text-container--desc"
            size={TEXT_TYPE.body}
            tag="div"
            color="white"
            value="Actually, it started in Portland, Oregon with a puff, then a bad cough, that led to a thought — why not make a safer cartridge? So we did and the rest is history."
          />
        </div>
      </section>
    );
  }
}

export default AboutUsHero;
