import React from 'react';

import { TweenMax, TimelineMax, Power2 } from 'gsap/TweenMax';
import { TEXT_TYPE } from '../../types/textTypes';
import withMediaTypes from '../../types/withMediaTypes';

import BgImage from '../BgImage';
import Text from '../Text';

import { removeWidows } from '../../utils/helpers';

class AboutUsSlider extends React.PureComponent {
  descriptionRef = React.createRef();

  part1Ref = React.createRef();

  part2Ref = React.createRef();

  part3Ref = React.createRef();

  
  static propTypes = {
    ...withMediaTypes,
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      copy: '',
    };
  }

  componentDidMount() {
    TweenMax.set(
      ['.AboutUsSlider--part3--bg-image', '.AboutUsSlider--part3--description'],
      {
        autoAlpha: 0,
        display: 'none',
      },
    );
    setTimeout(() => {
      this.setCopy('Safety');
      this.select('Safety');
    }, 1000);
  }

  select = (mood) => {
    const el = this.descriptionRef.current;
    const { selected } = this.state;

    // Mood didn't change, don't animate
    if (selected.toLowerCase() === mood) return;

    TweenMax.to(`.AboutUsSlider--part3--bg-image:not(.bg-${mood})`, 0.5, {
      autoAlpha: 0,
      display: 'none',
    });
    TweenMax.to(`.AboutUsSlider--part3--bg-image.bg-${mood}`, 1, {
      autoAlpha: 1,
      display: 'block',
    });

    new TimelineMax()
      .to(el, 0.4, { y: 10, autoAlpha: 0, ease: Power2.easeOut })
      .call(this.setCopy, [mood], this)
      .delay(0.2)
      .to(el, 0.8, {
        y: 0,
        autoAlpha: 1,
        display: 'block',
        ease: Power2.easeOut,
      });
  };

  setCopy = (selected) => {
    const copy = {
      Safety: 'Select tests to the highest standards for potency and pesticides in every state we\'re in. In fact, our internal standards are more stringent than state compliance requirements.',
      Sourcing: 'Our partnership with farmers is built with intent, integrity and transparency.<br/>We never add harmful cutting agents such as vegetable gylcerine (VG), propylene glycol (PG) or Vitamin E Acetate to our cartridges.',
      Consistency: 'Each cartridge is hand-filled to ensure quality, followed by further inspection through four quality control stations.<br/>Ensuring a full flavor and smooth feel without fail. Every time.',
      Innovation: 'Our story started when our founder identified a cleaner, safer option for vaporizer pen technology.<br/>From there, we\'ve worked to innovate and set standards around testing, company culture, brand and more.',
      Commitment: 'We are constantly innovating for the future of the industry.<br/>All for a better cannabis experience.<br/>And we promise to never stop getting better.',
    }[selected];
    // eslint-disable-next-line
    const slideMood = {
      Safety: 'dialed',
      Sourcing: 'lively',
      Consistency: 'fresh',
      Innovation: 'chill',
      Commitment: 'zzzz',
    }[selected];
    this.setState({
      copy,
      selected,
    });
  };

  render() {
    const {
      selected, copy,
    } = this.state;
    const isMobileSuffix = window.outerWidth < 768 ? '-mobile' : '';

    const bgUrl = {
      Safety: `about-us/safety${isMobileSuffix}.jpg`,
      Sourcing: `about-us/sourcing${isMobileSuffix}.jpg`,
      Consistency: `about-us/consistency${isMobileSuffix}.jpg`,
      Innovation: `about-us/innovation${isMobileSuffix}.jpg`,
      Commitment: `about-us/commitment${isMobileSuffix}.jpg`,
    };
    return (
      <section className="AboutUsSlider" ref={this.part3Ref}>
        <div className="AboutUsSlider--part3" ref={this.part3Ref}>
          <div className="AboutUsSlider--part3--bg">
            <BgImage
              className="AboutUsSlider--part3--bg-image bg-Safety"
              url={bgUrl.Safety}
              critical
            />
            <BgImage
              className="AboutUsSlider--part3--bg-image bg-Sourcing"
              style={{ opacity: 0 }}
              url={bgUrl.Sourcing}
              critical
            />
            <BgImage
              className="AboutUsSlider--part3--bg-image bg-Consistency"
              style={{ opacity: 0 }}
              url={bgUrl.Consistency}
              critical
            />
            <BgImage
              className="AboutUsSlider--part3--bg-image bg-Innovation"
              style={{ opacity: 0 }}
              url={bgUrl.Innovation}
              critical
            />
            <BgImage
              className="AboutUsSlider--part3--bg-image bg-Commitment"
              style={{ opacity: 0 }}
              url={bgUrl.Commitment}
              critical
            />
          </div>
          <Text
            className="AboutUsSlider--part3--header fim fim-fade-in"
            size={TEXT_TYPE.h3}
            tag="p"
            color="white"
            value="This is Better."
          />
          <div className="AboutUsSlider--part3--nav fim fim-fade-in">
            <button
              className={selected === 'Safety' ? 'selected' : ''}
              onClick={() => {
                this.select('Safety');
              }}
              type="button"
            >
              Safety
            </button>
            <button
              className={selected === 'Sourcing' ? 'selected' : ''}
              onClick={() => {
                this.select('Sourcing');
              }}
              type="button"
            >
              Sourcing
            </button>
            <button
              className={selected === 'Consistency' ? 'selected' : ''}
              onClick={() => {
                this.select('Consistency');
              }}
              type="button"
            >
              Consistency
            </button>
            <button
              className={selected === 'Innovation' ? 'selected' : ''}
              onClick={() => {
                this.select('Innovation');
              }}
              type="button"
            >
              Innovation
            </button>
            <button
              className={selected === 'Commitment' ? 'selected' : ''}
              onClick={() => {
                this.select('Commitment');
              }}
              type="button"
            >
              Commitment
            </button>
          </div>
          <div
            ref={this.descriptionRef}
            className="AboutUsSlider--part3--description"
            dangerouslySetInnerHTML={{ __html: removeWidows(copy, 0) }}
          />
        </div>
      </section>
    );
  }
}

export default AboutUsSlider;
